.Oops {
    font-size: 64px;
    font-weight: bold;
    padding: 10rem 0 0 0;
}

.findPage {
    font-size: 32px;
    color: #363561;
    padding: 2rem 0;
}

.go-back {
    background: #3CC4E5;
    color: #363561;
    border-radius: 20px;
    font-weight: 500;
    padding: .5rem 3rem;
}

@media only screen and (max-width: 768px) {
    .Oops {
        padding-top: 1rem !important;
    }
    .lost-image {
        margin-top: 2rem;
    }
    /* .Oopscontainer {
        text-align: center;
    } */
}