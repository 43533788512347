.button {
    background-color: var(--color-button);
    /* border-radius: 50px; */
    border-radius: 10px;
    color: white;
    border: none;
    height: 40px;
    padding: .5rem 3rem;
    cursor: pointer;
    transition: 300ms;
    font-size: var(--button-font-small);
    font-weight: 500;
}

.button:hover {
    background-color: var(--color-button-hover);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.button:focus {
    outline: none;
    border: none;
}

@media only screen and (min-width: 768px) {
    .button {
        height: 45px;
        font-size: var(--button-font-big);
    }
}