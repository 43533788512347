@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');
/* slider configurations */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 30px;
  width: 30px;
  outline: black;
  background-size: 100%, 100%;
  background-image: none;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  color: blue;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.fa-check-circle:before {
  content: '\f058';
  color: #159522;
}

/* how it works css */
.how {
  background: #f4f4f4;
  margin-top: 0rem;
  margin-bottom: 2rem;
}
.how-h2 {
  font-size: 24px;
  font-family: 'Russo One', sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  color: #363561;
  padding-top: 2rem;
}
.how-p {
  font-size: 18px;
  color: #000;
}
.how-cards-img img {
  padding: 1rem 1rem 3rem;
}
.how-card {
  width: 80%;
  height: 500px;
  margin: 10px;
}
.how-card .num h1 {
  color: white;
  font-weight: 500;
  font-style: normal;
  font-size: 45px;
}
.how-blue {
  background: #363561;
}

.how-pink {
  background: #ef4871;
}

.how-yellow {
  background: #fdb515;
}

.how-texting {
  width: 200px;
  padding: 20px;
  height: 100px;
  background: #ffffff;
  border-radius: 50%;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 20px auto;
}

.how-texting p {
  color: black;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
}

.card-img img {
  width: 200px;
  height: 300px;
}

.benefit-section h2 {
  font-family: 'Russo One', sans-serif;
  font-size: 24px;
  text-align: center;
  margin: 20px;
  color: #363561;
  font-weight: 800;
}

.benefit-text {
  text-align: center;
  font-size: var(--p-font-small);
  margin: auto;
}
.benefit-section-grid {
  display: grid;
  grid-template-columns: 4fr 2fr 4fr;
  grid-gap: 35px;
}

.box strong {
  font-size: 20px;
}

.benefit-section-grid .benefit-1 {
  text-align: center;
}

.benefit-section-grid .box {
  padding: 25px;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -khtml-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -moz-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  margin: 25px 0;
  height: auto;
  width: auto;
}

.benefit-section-grid .benefit-1 img {
  max-width: 100px;
  height: 100px;
  padding-bottom: 15px;
}

.benefit-section-grid .benefit-2 img {
  max-width: 350px;
  padding-top: 40px;
}

/* Spelling Section */
.spelling-section {
  margin: auto;
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.spelling-signup {
  background-color: #ef4871;
  padding: 10px 30px;
  border-radius: 25px;
  color: white;
  text-decoration: none;
}

.spelling-signup:hover {
  text-decoration: none;
  color: white;
}

.spelling-img {
  text-align: center;
  background-color: #f4f4f4;
}

.spelling-img img {
  max-width: 420px;
  padding: 20px 40px;
  border-radius: 20px;
}

.spelling-desc {
  margin-bottom: 2rem;
}

.spelling-desc h3 {
  padding-top: 65px;
  font-weight: 500;
}

.spelling-desc p {
  font-size: var(--p-font-small)
}

/* Testimony Section*/
.testimony-section h2 {
  font-family: 'Russo One', sans-serif;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  margin: 20px;
  font-weight: 800;
  color: #363561;
}

.testimony-section-box {
  border: 3px solid #ef4871;
  border-radius: 10px;
  margin: 30px 0;
  padding: 20px 40px;
}

.testimony-section-box h3 {
  float: right;
  padding-bottom: 10px;
}

.testimony-section-box .name {
  float: right;
  font-weight: bold;
}

/* Plan Section */
.plan-section {
  margin: 5px auto;
  text-align: center;
  border: 3px solid #ef4871;
  border: 3px solid #363561;
  padding: 0;
  border-radius: 10px;
}

.grid-outer {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  background-color: #363561;
  padding: 7px;
  color: #ffffff;
  font-size: 16px;
}

.plan-section-grid {
  padding: 7px;
}

.grid-inner {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  padding: 5px 15px;
}

.grid-inner span {
  font-weight: bold;
}

.grid-inner i {
  color: #ef4871;
  font-size: 22px;
  padding-right: 7px;
}

/* Extra Section */
.spread {
  margin-top: 50px;
  background-color: #f4f4f4;
}
.extra-section {
  margin: auto;
  display: grid;
  grid-template-columns: 4fr 3fr;
  text-align: center;
}

.extra-section-img img {
  max-width: 300px;
}

.extra-section-desc {
  margin-top: 60px;
}

.extra-section-desc h2 {
  padding: 30px 0;
}

/* .extra-section-desc a {
  background-color: #faa819;
  color: #363561;
  padding: 12px 45px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 18px;
} */

/* .extra-section-desc a:hover {
  text-decoration: none;
} */


.iframe-container iframe {
  width: 100%;
  padding: 5px
}

/* Responsiveness */

@media only screen and (min-width: 768px) {
  .how-h2, .benefit-section h2, .testimony-section h2 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .how-contain {
    flex-wrap: wrap;
  }
  .benefit-section-grid {
    grid-template-columns: 4fr;
  }

  .benefit-section-grid .benefit-2 img {
    display: none;
    grid-gap: unset;
  }
  .how-cards {
    display: grid;
  }
  .benefit-section-grid {
    grid-gap: unset;
  }

  #resp-benefit {
    margin-top: 0;
    padding-top: 0;
  }

  .spelling-section {
    grid-template-columns: 4fr;
    margin: 0 auto;
    text-align: center;
  }

  .extra-section {
    grid-template-columns: 4fr;
    max-width: unset;
  }

  .extra-section-img img {
    display: none;
  }

.spread {
    height: 250px;
    margin-bottom: 3rem;
  }
}

@media (max-width: 425px) {
  .spelling-img img {
    max-width: 300px;
  }

  .testimony-section-box {
    border: 3px solid #ef4871;
    border-radius: 10px;
    padding: 10px 5px;
  }

  .testimony-section-box h3 {
    font-size: 19px;
    font-weight: bold;
  }

  .plan-section {
    margin: 5px auto;
  }

  .grid-outer h4 {
    font-size: 16px;
  }

  .extra-section {
    margin: 30px 0;
  }

  .spread {
    height: 280px;
  }
  .spelling-img {
    margin-top: 30px;
  }
  .extra-section-desc a {
    padding: 12px 20px;
  }
}

@media only screen and (min-width: 768px) {
  .benefit-text, .spelling-desc p  {
    font-size: var(--p-font-big);
  }
}