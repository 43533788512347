.reset-password-section { 
    display: grid;
    margin: auto;
    place-items: center;
    height: 100%;
}

.reset_password-form, .fname {
    height: 38px !important;
}
  
#form-input {
    height: 43px !important;
    border-radius: 10px;
}
  
.reset_password-form{
    margin: auto;
    width: 50%;
}
  
.error {
    color: red;
    text-align: center;
}
  
  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #form-input {
      width: 100% !important;
  }
    .checkedStyle {
      display: flex;
    }
    .checkedStyle [type=radio]:checked + img {
      border-radius: 30px;
    }
  }