.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
}

.input-check,
.slider:before {
    position: absolute;
    content: "";
    height: 34px;
    width: 32px;
    left: -1.5px;
    top: -1.5px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    cursor: pointer;
}

input {
    text-align: center;
}

::-webkit-input-placeholder {
    text-align: center;
}

:-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
}

::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
}

:-ms-input-placeholder {
    text-align: center;
}

select {
    text-align: center;
    text-align-last: center;
    /* webkit*/
}

.input-check,
input:checked+.slider {
    background-color: #fff;
}

.input-check,
input:focus+.slider {
    box-shadow: 0 0 1px #fff;
}

.input-check,
input:checked+.slider:before {
    -webkit-transform: translateX(28px);
    -ms-transform: translateX(28px);
    transform: translateX(28px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
    border: .1rem red solid;
}

.slider.round:before {
    border-radius: 50%;
    background-color: red;
}

.radio-toolbar input[type="radio"] {
    display: none;
}

.radio-toolbar label {
    display: inline-block;
    font-size: 18px;
    width: 100%;
    height: 50px;
    border: 1px solid #fff;
    color: white;
    /* border-radius: 5px; */
    padding-top: .5rem;
    cursor: pointer;
}
.Annual .radio-toolbar label {
    background: #EF4871;
    margin-bottom: unset !important;
}
.monthly .radio-toolbar label {
    background: #363561cb;
    margin-bottom: unset !important;
}
.free .radio-toolbar label {
    background: #3CC4E5;
    margin-bottom: unset !important;
}
.button-div label {
    margin-bottom: unset !important;
}
.buttons{
    background-color: #EF4871;
    border-radius: 20px;
    color: white !important;
    border: none;
    padding: .5rem 2rem;
    cursor: pointer;
  }

/* .radio-toolbar [type="radio"]:checked+label {
    background: #fff;
    color: #000;
    border: 2px solid #000;
} */
.dollar,.naira {
    font-size: 36px;
    font-weight: bold;
    padding-top: 1rem;
}
.Annual .radio-toolbar label:hover  {
    background-color: #f30b45;
}
.Annual .radio-toolbar [type="radio"]:checked+label {
    border: 2px solid #000;
}
.monthly .radio-toolbar label:hover {
    background: #201f4b;
}
.monthly .radio-toolbar [type="radio"]:checked+label {
    border: 2px solid #000;
}
.free .radio-toolbar label:hover {
    background: #167d97;
}
.free .radio-toolbar [type="radio"]:checked+label {
    border: 2px solid #000;
}
.radio-toolbar label:hover {
    background-color: #bbb;
    color: #FFF;
}

.Annual .card-body, 
.monthly .card-body, 
.free .card-body {
    padding: unset !important;
}

.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100px;
    height: 30px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    /* top: 2px; */
    left: 0px;
    width: 45px;
    height: 30px;
    border-radius: 45px;
    transition: 0.2s;
    background: #EF4871;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 0px);
    transform: translateX(-100%);
  }
  
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }
  
  .pricing-best-offer {
      width: 5rem;
      position: absolute;
      margin-top: -1rem;
      margin-left: 1rem;
  }
  .best-offer-upgrade {
    width: 5rem;
    position: absolute;
    margin-top: -1rem;
    margin-left: 2.5rem;
  }
  .free-plan-icon,
  .monthly-plan-icon {
      height: 8rem;
  }