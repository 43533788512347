.modal-header {
   border-bottom: unset !important;
}
.close-btn {
    border: 2px solid red;
    border-radius: 50%;
}
.modal-header .close {
    padding: unset !important;
}
.parentsloginBtn  {
    background-color: #EF4871;
    border-radius: 20px;
    color: white !important;
    border: none;
    padding: .5rem 2rem;
    cursor: pointer;
}
/* .forgotpasswordBtn {
    position: absolute;
    right: 1rem;
} */
.text-danger {
    color: red !important;
}
.closeimg img {
    width: 2rem;
    float: right;
    margin-top: -.8em;
}
.closeimg:hover {
    cursor: pointer;
    float: right;

}
.buttons{
    background-color: #EF4871;
    border-radius: 20px;
    color: white !important;
    border: none;
    padding: .5rem 2rem;
    cursor: pointer;
  }

.button-div {
    margin-top: 3rem;
    margin: auto;
}
#form-input-2 {
    width: 100% !important;
    border-radius: 10px;
}

/* .second-login form {
    text-align: center;
} */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .lola {
        display: none;
    }
    .second-login h3 {
        display: none;
    }
    /* .closeimg img {
        width: 10%;
    } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .lola {
        display: block;
    }
    .second-login h3 {
        display: block;
    }
}







