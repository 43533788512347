.toast_success_override, .toast_error_override {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
    background-color: rgb(21, 122, 21);
}

.toast_error_override {
    background-color: rgb(163, 52, 52);
}
