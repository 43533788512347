/* .active {
    background-color: #444;
    color: white !important;
} */

.loading {
    animation: 0.5s linear infinite rotate;
    height: 100px; 
    color: #777;
}

@-webkit-keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

/* body.modal-open > :not(.modal) {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
} */

.copy-area {
  position: relative;
  display: inline-block;
}

.copy-feedback {
  visibility: hidden;
  position: absolute;
  top: -10px;
  left: 25px;
  color: white;
  padding: 3px 10px;
  border-radius: 3px;
  transition: 100ms;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-feedback.active {
  visibility: visible;
  background-color: rgba(66, 206, 85, 0.699);
}

.bg-grey {
  background-color: #eee
}