.error {
    color: red;
    /* margin-top: .5rem; */
    margin-left: 1rem;
}

.name {
    width: 100%; 
    padding: 1rem; 
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2)
}
.is-open {
    display: block;
}

.pencil {
    position: absolute;
    top: 13rem;
    right: 1.5rem;
}
.pencil-email {
    position: absolute;
    top: 22.5rem;
    right: 1.5rem;
}
.pencil-password {
    position: absolute;
    top: 31.5rem;
    right: 1.5rem;
}

.details-btn, .acct-button, .plan-details-button {
    background: #353561;
    border-radius: 15px;
    border: none;
    color: white;
    padding: .5rem 1rem;
    width: 12rem;
    height: 2.5rem;
}
.separator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2)
}
.cancel {
    border: none;
    background: none;
    color: red;
}
.best-offer {
    height: 3rem;
}
.name-input-class {
    width: 100%; 
    padding: 1rem; 
    outline: none !important;
    border:2px solid #0160c7;
    border-radius: 5px;
}

@media screen and (max-width: 767px) {

    .sidebar.col-sm-3 {
        max-width: unset;
    }
    .nav-keys-sm {
        position: absolute;
        background: white;
        z-index: 99;
        height: 100%;
        width: 20rem;
    }
    .nav-keys-sm .flex-column {
        margin-top: 5rem !important;
    }
    div.tab-content {
        padding: 0 1rem;
    }
    .closebtn {
        float: right;
    }
}

@media screen and (max-width: 400px) {

    .closebtn a {
        float: right !important;
    }

}

@media only screen and (min-width:320px) {

    .closebtn {
        font-size: 2rem;
        display: block;
        color: black;
        text-decoration: none;
    }

    .hambuger {
        margin-top: 2rem;
        display: block;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .sidebar.col-sm-3 {
        max-width: unset;
    }

    .closebtn {
        font-size: 2rem;
        display: block;
        color: black;
        text-decoration: none;
    }

    .hambuger {
        margin-top: 2rem;
        display: block;
    }

    .center {
        transform: translate(0%, 20%);
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    .closebtn {
        font-size: 2rem;
        display: block;
        text-decoration: none;
    }

    .hambuger {
        margin-top: 2rem;
        display: block;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .closebtn {
        font-size: 2rem;
        display: none;
        color: black;
        text-decoration: none;
    }

    .hambuger {
        display: none;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .closebtn {
        font-size: 2rem;
        display: none;
        color: black;
        text-decoration: none;
    }

    .hambuger {
        display: none;
    }
}