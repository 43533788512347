.pdhNavLink {
    background-color: #363561;
    border-radius: 20px;
    color: white;
    max-width: 100px;
}
.nav-dropdown{
    cursor: pointer;
}
.nav-dropdown a, .nav-dropdown2{
    color: #363561
}

.navbar-brand img {
    max-height: 80px;
    padding-top: 10px;
}

.navbar { 
    padding: unset !important;
}

.fa-bell-o path {
    stroke: red;
    stroke-width: 30px;
}

.txt{
    background-color:red;
    position: absolute;
    padding:3px;
    top: 5px;
    left: 30px;
    border-radius: 25px;
}

.Item_item__34h1P {
    font-size: 16px !important;
}

.Submenu_submenu__1PCnm > ul {
    margin-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.border-btm {
    border-bottom: 1px #EFEFEF solid;
    width: 100%;
}
.fa-cog:before, .fa-eye:before, .fa-wrench:before, .fa.fa-bar-chart:before,.fa.fa-user-o:before {
    color: #DA304A !important;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .bell-notify {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem !important;
    }
    .txt{
        top: 15px;
        left: 45px;
    }
}