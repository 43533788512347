.download {
    background-color: #EF4871;
    border-radius: 20px;
    color: white;
    border: none;
    /* border: 1px solid transparent; */
    padding: .5rem 2rem;
    cursor: pointer;
}

.download:hover {
    background-color: #f0184e;
    transition: 300ms;
    text-decoration: none;
    color: white;
    /* border: 1px solid white; */
}

.download:focus {
    outline: none;
    border: none;
}