@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Russo+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap);
:root {
	--color-button: #f0184e;
	--color-button-hover: #EF4871;
  --button-font-big: 15px;
  --button-font-small: 13px;
	--p-font-big: 18px;
  --p-font-small: 14px;
  --h2-font-big: 35px;
  --h2-font-small: 30px;
}

* {
  scroll-behavior: smooth;
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Roboto', sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pdhNavLink {
    background-color: #363561;
    border-radius: 20px;
    color: white;
    max-width: 100px;
}
.nav-dropdown{
    cursor: pointer;
}
.nav-dropdown a, .nav-dropdown2{
    color: #363561
}

.navbar-brand img {
    max-height: 80px;
    padding-top: 10px;
}

.navbar { 
    padding: unset !important;
}

.fa-bell-o path {
    stroke: red;
    stroke-width: 30px;
}

.txt{
    background-color:red;
    position: absolute;
    padding:3px;
    top: 5px;
    left: 30px;
    border-radius: 25px;
}

.Item_item__34h1P {
    font-size: 16px !important;
}

.Submenu_submenu__1PCnm > ul {
    margin-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.border-btm {
    border-bottom: 1px #EFEFEF solid;
    width: 100%;
}
.fa-cog:before, .fa-eye:before, .fa-wrench:before, .fa.fa-bar-chart:before,.fa.fa-user-o:before {
    color: #DA304A !important;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .bell-notify {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem !important;
    }
    .txt{
        top: 15px;
        left: 45px;
    }
}
.button {
    background-color: var(--color-button);
    /* border-radius: 50px; */
    border-radius: 10px;
    color: white;
    border: none;
    height: 40px;
    padding: .5rem 3rem;
    cursor: pointer;
    transition: 300ms;
    font-size: var(--button-font-small);
    font-weight: 500;
}

.button:hover {
    background-color: var(--color-button-hover);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.button:focus {
    outline: none;
    border: none;
}

@media only screen and (min-width: 768px) {
    .button {
        height: 45px;
        font-size: var(--button-font-big);
    }
}

.childlogin-image img {
  width: 30%;
  margin-top: 1rem;
}

.forgot-password {
  padding-left: 2rem;
  margin-top: 1.5rem;
}
.notification-close:hover {
  cursor: pointer;
}

.login-container form {
  text-align: center;
}

@media (max-width: 770px) {
  .lola-img {
    width: 30%;
  }
  
  .forgot-password {
    padding-left: 1rem;
    margin-top: 1.5rem;
  }
}
  
   /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .childlogin-image img {
    width: unset;
    margin-top: 2rem;
  }
  .code-login {
    font-size: 24px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .childlogin-image img {
    width: unset;
    margin-top: 2rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .childlogin-image img {
    width: unset;
    margin-top: 2rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .childlogin-image img {
    width: 30%;
    margin-top: 2rem;
  }
}
.error {
    color: red;
    /* margin-top: .5rem; */
    margin-left: 1rem;
}

.name {
    width: 100%; 
    padding: 1rem; 
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2)
}
.is-open {
    display: block;
}

.pencil {
    position: absolute;
    top: 13rem;
    right: 1.5rem;
}
.pencil-email {
    position: absolute;
    top: 22.5rem;
    right: 1.5rem;
}
.pencil-password {
    position: absolute;
    top: 31.5rem;
    right: 1.5rem;
}

.details-btn, .acct-button, .plan-details-button {
    background: #353561;
    border-radius: 15px;
    border: none;
    color: white;
    padding: .5rem 1rem;
    width: 12rem;
    height: 2.5rem;
}
.separator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2)
}
.cancel {
    border: none;
    background: none;
    color: red;
}
.best-offer {
    height: 3rem;
}
.name-input-class {
    width: 100%; 
    padding: 1rem; 
    outline: none !important;
    border:2px solid #0160c7;
    border-radius: 5px;
}

@media screen and (max-width: 767px) {

    .sidebar.col-sm-3 {
        max-width: unset;
    }
    .nav-keys-sm {
        position: absolute;
        background: white;
        z-index: 99;
        height: 100%;
        width: 20rem;
    }
    .nav-keys-sm .flex-column {
        margin-top: 5rem !important;
    }
    div.tab-content {
        padding: 0 1rem;
    }
    .closebtn {
        float: right;
    }
}

@media screen and (max-width: 400px) {

    .closebtn a {
        float: right !important;
    }

}

@media only screen and (min-width:320px) {

    .closebtn {
        font-size: 2rem;
        display: block;
        color: black;
        text-decoration: none;
    }

    .hambuger {
        margin-top: 2rem;
        display: block;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .sidebar.col-sm-3 {
        max-width: unset;
    }

    .closebtn {
        font-size: 2rem;
        display: block;
        color: black;
        text-decoration: none;
    }

    .hambuger {
        margin-top: 2rem;
        display: block;
    }

    .center {
        -webkit-transform: translate(0%, 20%);
                transform: translate(0%, 20%);
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    .closebtn {
        font-size: 2rem;
        display: block;
        text-decoration: none;
    }

    .hambuger {
        margin-top: 2rem;
        display: block;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .closebtn {
        font-size: 2rem;
        display: none;
        color: black;
        text-decoration: none;
    }

    .hambuger {
        display: none;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .closebtn {
        font-size: 2rem;
        display: none;
        color: black;
        text-decoration: none;
    }

    .hambuger {
        display: none;
    }
}
.Oops {
    font-size: 64px;
    font-weight: bold;
    padding: 10rem 0 0 0;
}

.findPage {
    font-size: 32px;
    color: #363561;
    padding: 2rem 0;
}

.go-back {
    background: #3CC4E5;
    color: #363561;
    border-radius: 20px;
    font-weight: 500;
    padding: .5rem 3rem;
}

@media only screen and (max-width: 768px) {
    .Oops {
        padding-top: 1rem !important;
    }
    .lost-image {
        margin-top: 2rem;
    }
    /* .Oopscontainer {
        text-align: center;
    } */
}
.toast_success_override, .toast_error_override {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
    background-color: rgb(21, 122, 21);
}

.toast_error_override {
    background-color: rgb(163, 52, 52);
}

/* .active {
    background-color: #444;
    color: white !important;
} */

.loading {
    -webkit-animation: 0.5s linear infinite rotate;
            animation: 0.5s linear infinite rotate;
    height: 100px; 
    color: #777;
}

@-webkit-keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}

/* body.modal-open > :not(.modal) {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
} */

.copy-area {
  position: relative;
  display: inline-block;
}

.copy-feedback {
  visibility: hidden;
  position: absolute;
  top: -10px;
  left: 25px;
  color: white;
  padding: 3px 10px;
  border-radius: 3px;
  transition: 100ms;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-feedback.active {
  visibility: visible;
  background-color: rgba(66, 206, 85, 0.699);
}

.bg-grey {
  background-color: #eee
}
.reset-password-section { 
    display: grid;
    margin: auto;
    place-items: center;
    height: 100%;
}

.reset_password-form, .fname {
    height: 38px !important;
}
  
#form-input {
    height: 43px !important;
    border-radius: 10px;
}
  
.reset_password-form{
    margin: auto;
    width: 50%;
}
  
.error {
    color: red;
    text-align: center;
}
  
  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #form-input {
      width: 100% !important;
  }
    .checkedStyle {
      display: flex;
    }
    .checkedStyle [type=radio]:checked + img {
      border-radius: 30px;
    }
  }
*{
    padding: 0;
    margin: 0;
  }

  .mb-2, .my-2 {
    margin-bottom: unset !important;
  }
  
  .App {
    text-align: unset !important;
  }
  .signupBtn {
    background-color: #EF4871;
    border-radius: 20px;
    color: white !important;
    border: none;
    padding: .5rem 2rem;
    cursor: pointer;
  }
  
  .center-form, .fname {
    height: 38px !important;
  }
  
  /* .center-form input {
    width: 50%;
  } */
  
  #form-input {
    height: 46px !important;
    border-radius: 10px;
  }
  
  .center-form {
    margin: auto;
  }
  
  .error {
    color: red;
  }
  
.buttons{
    background-color: #EF4871;
    border-radius: 20px;
    color: white !important;
    border: none;
    padding: .5rem 2rem;
    cursor: pointer;
  }
  
  /* HIDE RADIO */
  .checkedStyle [type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* IMAGE STYLES */
  [type=radio] + img {
    cursor: pointer;
  }
  
  /* CHECKED STYLES */
  .checkedStyle [type=radio]:checked + img {
    border: 4px solid #EF4871;
    border-radius: 50px;
  }
  
  .parent-signup {
      width: 70px;
      border-top: 3px solid #EF4871;
  }
  
  .center-icon {
      width: 12rem;
  }
  
  #basic-label,
  #monthly-label,
  #annual-label {
    background-color: white;
  }
  
  #monthly-label {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  
  .reset-password {
    padding-top: 2rem;
  }

  .button-div {
    margin-bottom: 100px !important;
  }
  
  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #form-input {
      width: 100% !important;
  }
    .checkedStyle {
      display: flex;
    }
    .checkedStyle [type=radio]:checked + img {
      border-radius: 20px;
    }
  }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

  #annual-label .imgstyle, 
  #monthly-label .imgstyle,
  #basic-label .imgstyle  {
    width: 8rem !important;
  }
  .checkedStyle [type=radio]:checked + img {
    border-radius: 30px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #basic-label {
    width: unset;
  }

  #annual-label .imgstyle, 
  #monthly-label .imgstyle,
  #basic-label .imgstyle  {
    width: 12rem !important;
  }
  .checkedStyle [type=radio]:checked + img {
    border-radius: 30px;
  }
}
.accordion {
  margin: 2rem auto;
   text-align: justify;
}
.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid rgb(211, 210, 210);
}
.accordion-title:hover, .accordion-title:active {
  background-color: #dbdedf;
}
.accordion-title,
.accordion-content {
  padding: 1rem;
}
.accordion-content {
   border: 1px solid rgb(211, 210, 210);
}
.modal-header {
   border-bottom: unset !important;
}
.close-btn {
    border: 2px solid red;
    border-radius: 50%;
}
.modal-header .close {
    padding: unset !important;
}
.parentsloginBtn  {
    background-color: #EF4871;
    border-radius: 20px;
    color: white !important;
    border: none;
    padding: .5rem 2rem;
    cursor: pointer;
}
/* .forgotpasswordBtn {
    position: absolute;
    right: 1rem;
} */
.text-danger {
    color: red !important;
}
.closeimg img {
    width: 2rem;
    float: right;
    margin-top: -.8em;
}
.closeimg:hover {
    cursor: pointer;
    float: right;

}
.buttons{
    background-color: #EF4871;
    border-radius: 20px;
    color: white !important;
    border: none;
    padding: .5rem 2rem;
    cursor: pointer;
  }

.button-div {
    margin-top: 3rem;
    margin: auto;
}
#form-input-2 {
    width: 100% !important;
    border-radius: 10px;
}

/* .second-login form {
    text-align: center;
} */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .lola {
        display: none;
    }
    .second-login h3 {
        display: none;
    }
    /* .closeimg img {
        width: 10%;
    } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .lola {
        display: block;
    }
    .second-login h3 {
        display: block;
    }
}








#form-input {
  border-radius: 10px;
  width: 100%;
}
.childlogin-image img {
  width: 30%;
  margin-top: 1rem;
}

.code-login {
  font-size: 24px;
}

#login-form-input {
  width: 50%;
  height: 43px !important;
  background-color: rgba(239, 72, 113, 0.06);
  border-radius: 10px;
}

.forgot-password {
  padding-left: 2rem;
  margin-top: 1.5rem;
}
.notification-close:hover {
  cursor: pointer;
}

.login-container form {
  text-align: center;
}

#login-form-input {
  border: 1px solid darkgrey;
}
@media (max-width: 770px) {
  .lola-img {
    width: 30%;
  }
  
  .forgot-password {
    padding-left: 1rem;
    margin-top: 1.5rem;
  }
  
  .login-input-style {
    width: 50%;
    margin: auto;
  }
}
  
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .childlogin-image img {
    width: unset;
    margin-top: 2rem;
  }
  .login-input-style {
    width: unset;
  }
  #login-form-input {
    width: 100%;
  }
  .code-login {
    font-size: 22px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .childlogin-image img {
    width: unset;
    margin-top: 2rem;
  }
  .login-input-style {
    width: unset;
  }
  #login-form-input {
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .childlogin-image img {
    width: unset;
    margin-top: 2rem;
  }
  .login-input-style {
    width: unset;
  }
  #login-form-input {
    width: 70%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .childlogin-image img {
    width: 30%;
    margin-top: 2rem;
  }
  #login-form-input {
    width: 50%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    transition: .4s;
}

.input-check,
.slider:before {
    position: absolute;
    content: "";
    height: 34px;
    width: 32px;
    left: -1.5px;
    top: -1.5px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    cursor: pointer;
}

input {
    text-align: center;
}

::-webkit-input-placeholder {
    text-align: center;
}

:-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
}

::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
}

:-ms-input-placeholder {
    text-align: center;
}

select {
    text-align: center;
    text-align-last: center;
    /* webkit*/
}

.input-check,
input:checked+.slider {
    background-color: #fff;
}

.input-check,
input:focus+.slider {
    box-shadow: 0 0 1px #fff;
}

.input-check,
input:checked+.slider:before {
    -webkit-transform: translateX(28px);
    transform: translateX(28px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
    border: .1rem red solid;
}

.slider.round:before {
    border-radius: 50%;
    background-color: red;
}

.radio-toolbar input[type="radio"] {
    display: none;
}

.radio-toolbar label {
    display: inline-block;
    font-size: 18px;
    width: 100%;
    height: 50px;
    border: 1px solid #fff;
    color: white;
    /* border-radius: 5px; */
    padding-top: .5rem;
    cursor: pointer;
}
.Annual .radio-toolbar label {
    background: #EF4871;
    margin-bottom: unset !important;
}
.monthly .radio-toolbar label {
    background: #363561cb;
    margin-bottom: unset !important;
}
.free .radio-toolbar label {
    background: #3CC4E5;
    margin-bottom: unset !important;
}
.button-div label {
    margin-bottom: unset !important;
}
.buttons{
    background-color: #EF4871;
    border-radius: 20px;
    color: white !important;
    border: none;
    padding: .5rem 2rem;
    cursor: pointer;
  }

/* .radio-toolbar [type="radio"]:checked+label {
    background: #fff;
    color: #000;
    border: 2px solid #000;
} */
.dollar,.naira {
    font-size: 36px;
    font-weight: bold;
    padding-top: 1rem;
}
.Annual .radio-toolbar label:hover  {
    background-color: #f30b45;
}
.Annual .radio-toolbar [type="radio"]:checked+label {
    border: 2px solid #000;
}
.monthly .radio-toolbar label:hover {
    background: #201f4b;
}
.monthly .radio-toolbar [type="radio"]:checked+label {
    border: 2px solid #000;
}
.free .radio-toolbar label:hover {
    background: #167d97;
}
.free .radio-toolbar [type="radio"]:checked+label {
    border: 2px solid #000;
}
.radio-toolbar label:hover {
    background-color: #bbb;
    color: #FFF;
}

.Annual .card-body, 
.monthly .card-body, 
.free .card-body {
    padding: unset !important;
}

.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100px;
    height: 30px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    /* top: 2px; */
    left: 0px;
    width: 45px;
    height: 30px;
    border-radius: 45px;
    transition: 0.2s;
    background: #EF4871;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 0px);
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }
  
  .pricing-best-offer {
      width: 5rem;
      position: absolute;
      margin-top: -1rem;
      margin-left: 1rem;
  }
  .best-offer-upgrade {
    width: 5rem;
    position: absolute;
    margin-top: -1rem;
    margin-left: 2.5rem;
  }
  .free-plan-icon,
  .monthly-plan-icon {
      height: 8rem;
  }
/* slider configurations */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 30px;
  width: 30px;
  outline: black;
  background-size: 100%, 100%;
  background-image: none;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  color: blue;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.fa-check-circle:before {
  content: '\f058';
  color: #159522;
}

/* how it works css */
.how {
  background: #f4f4f4;
  margin-top: 0rem;
  margin-bottom: 2rem;
}
.how-h2 {
  font-size: 24px;
  font-family: 'Russo One', sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  color: #363561;
  padding-top: 2rem;
}
.how-p {
  font-size: 18px;
  color: #000;
}
.how-cards-img img {
  padding: 1rem 1rem 3rem;
}
.how-card {
  width: 80%;
  height: 500px;
  margin: 10px;
}
.how-card .num h1 {
  color: white;
  font-weight: 500;
  font-style: normal;
  font-size: 45px;
}
.how-blue {
  background: #363561;
}

.how-pink {
  background: #ef4871;
}

.how-yellow {
  background: #fdb515;
}

.how-texting {
  width: 200px;
  padding: 20px;
  height: 100px;
  background: #ffffff;
  border-radius: 50%;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 20px auto;
}

.how-texting p {
  color: black;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
}

.card-img img {
  width: 200px;
  height: 300px;
}

.benefit-section h2 {
  font-family: 'Russo One', sans-serif;
  font-size: 24px;
  text-align: center;
  margin: 20px;
  color: #363561;
  font-weight: 800;
}

.benefit-text {
  text-align: center;
  font-size: var(--p-font-small);
  margin: auto;
}
.benefit-section-grid {
  display: grid;
  grid-template-columns: 4fr 2fr 4fr;
  grid-gap: 35px;
}

.box strong {
  font-size: 20px;
}

.benefit-section-grid .benefit-1 {
  text-align: center;
}

.benefit-section-grid .box {
  padding: 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  margin: 25px 0;
  height: auto;
  width: auto;
}

.benefit-section-grid .benefit-1 img {
  max-width: 100px;
  height: 100px;
  padding-bottom: 15px;
}

.benefit-section-grid .benefit-2 img {
  max-width: 350px;
  padding-top: 40px;
}

/* Spelling Section */
.spelling-section {
  margin: auto;
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.spelling-signup {
  background-color: #ef4871;
  padding: 10px 30px;
  border-radius: 25px;
  color: white;
  text-decoration: none;
}

.spelling-signup:hover {
  text-decoration: none;
  color: white;
}

.spelling-img {
  text-align: center;
  background-color: #f4f4f4;
}

.spelling-img img {
  max-width: 420px;
  padding: 20px 40px;
  border-radius: 20px;
}

.spelling-desc {
  margin-bottom: 2rem;
}

.spelling-desc h3 {
  padding-top: 65px;
  font-weight: 500;
}

.spelling-desc p {
  font-size: var(--p-font-small)
}

/* Testimony Section*/
.testimony-section h2 {
  font-family: 'Russo One', sans-serif;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  margin: 20px;
  font-weight: 800;
  color: #363561;
}

.testimony-section-box {
  border: 3px solid #ef4871;
  border-radius: 10px;
  margin: 30px 0;
  padding: 20px 40px;
}

.testimony-section-box h3 {
  float: right;
  padding-bottom: 10px;
}

.testimony-section-box .name {
  float: right;
  font-weight: bold;
}

/* Plan Section */
.plan-section {
  margin: 5px auto;
  text-align: center;
  border: 3px solid #ef4871;
  border: 3px solid #363561;
  padding: 0;
  border-radius: 10px;
}

.grid-outer {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  background-color: #363561;
  padding: 7px;
  color: #ffffff;
  font-size: 16px;
}

.plan-section-grid {
  padding: 7px;
}

.grid-inner {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  padding: 5px 15px;
}

.grid-inner span {
  font-weight: bold;
}

.grid-inner i {
  color: #ef4871;
  font-size: 22px;
  padding-right: 7px;
}

/* Extra Section */
.spread {
  margin-top: 50px;
  background-color: #f4f4f4;
}
.extra-section {
  margin: auto;
  display: grid;
  grid-template-columns: 4fr 3fr;
  text-align: center;
}

.extra-section-img img {
  max-width: 300px;
}

.extra-section-desc {
  margin-top: 60px;
}

.extra-section-desc h2 {
  padding: 30px 0;
}

/* .extra-section-desc a {
  background-color: #faa819;
  color: #363561;
  padding: 12px 45px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 18px;
} */

/* .extra-section-desc a:hover {
  text-decoration: none;
} */


.iframe-container iframe {
  width: 100%;
  padding: 5px
}

/* Responsiveness */

@media only screen and (min-width: 768px) {
  .how-h2, .benefit-section h2, .testimony-section h2 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .how-contain {
    flex-wrap: wrap;
  }
  .benefit-section-grid {
    grid-template-columns: 4fr;
  }

  .benefit-section-grid .benefit-2 img {
    display: none;
    grid-gap: unset;
  }
  .how-cards {
    display: grid;
  }
  .benefit-section-grid {
    grid-gap: unset;
  }

  #resp-benefit {
    margin-top: 0;
    padding-top: 0;
  }

  .spelling-section {
    grid-template-columns: 4fr;
    margin: 0 auto;
    text-align: center;
  }

  .extra-section {
    grid-template-columns: 4fr;
    max-width: unset;
  }

  .extra-section-img img {
    display: none;
  }

.spread {
    height: 250px;
    margin-bottom: 3rem;
  }
}

@media (max-width: 425px) {
  .spelling-img img {
    max-width: 300px;
  }

  .testimony-section-box {
    border: 3px solid #ef4871;
    border-radius: 10px;
    padding: 10px 5px;
  }

  .testimony-section-box h3 {
    font-size: 19px;
    font-weight: bold;
  }

  .plan-section {
    margin: 5px auto;
  }

  .grid-outer h4 {
    font-size: 16px;
  }

  .extra-section {
    margin: 30px 0;
  }

  .spread {
    height: 280px;
  }
  .spelling-img {
    margin-top: 30px;
  }
  .extra-section-desc a {
    padding: 12px 20px;
  }
}

@media only screen and (min-width: 768px) {
  .benefit-text, .spelling-desc p  {
    font-size: var(--p-font-big);
  }
}
.download {
    background-color: #EF4871;
    border-radius: 20px;
    color: white;
    border: none;
    /* border: 1px solid transparent; */
    padding: .5rem 2rem;
    cursor: pointer;
}

.download:hover {
    background-color: #f0184e;
    transition: 300ms;
    text-decoration: none;
    color: white;
    /* border: 1px solid white; */
}

.download:focus {
    outline: none;
    border: none;
}
