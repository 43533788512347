#form-input {
  border-radius: 10px;
  width: 100%;
}
.childlogin-image img {
  width: 30%;
  margin-top: 1rem;
}

.code-login {
  font-size: 24px;
}

#login-form-input {
  width: 50%;
  height: 43px !important;
  background-color: rgba(239, 72, 113, 0.06);
  border-radius: 10px;
}

.forgot-password {
  padding-left: 2rem;
  margin-top: 1.5rem;
}
.notification-close:hover {
  cursor: pointer;
}

.login-container form {
  text-align: center;
}

#login-form-input {
  border: 1px solid darkgrey;
}
@media (max-width: 770px) {
  .lola-img {
    width: 30%;
  }
  
  .forgot-password {
    padding-left: 1rem;
    margin-top: 1.5rem;
  }
  
  .login-input-style {
    width: 50%;
    margin: auto;
  }
}
  
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .childlogin-image img {
    width: unset;
    margin-top: 2rem;
  }
  .login-input-style {
    width: unset;
  }
  #login-form-input {
    width: 100%;
  }
  .code-login {
    font-size: 22px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .childlogin-image img {
    width: unset;
    margin-top: 2rem;
  }
  .login-input-style {
    width: unset;
  }
  #login-form-input {
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .childlogin-image img {
    width: unset;
    margin-top: 2rem;
  }
  .login-input-style {
    width: unset;
  }
  #login-form-input {
    width: 70%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .childlogin-image img {
    width: 30%;
    margin-top: 2rem;
  }
  #login-form-input {
    width: 50%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}