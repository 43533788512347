*{
    padding: 0;
    margin: 0;
  }

  .mb-2, .my-2 {
    margin-bottom: unset !important;
  }
  
  .App {
    text-align: unset !important;
  }
  .signupBtn {
    background-color: #EF4871;
    border-radius: 20px;
    color: white !important;
    border: none;
    padding: .5rem 2rem;
    cursor: pointer;
  }
  
  .center-form, .fname {
    height: 38px !important;
  }
  
  /* .center-form input {
    width: 50%;
  } */
  
  #form-input {
    height: 46px !important;
    border-radius: 10px;
  }
  
  .center-form {
    margin: auto;
  }
  
  .error {
    color: red;
  }
  
.buttons{
    background-color: #EF4871;
    border-radius: 20px;
    color: white !important;
    border: none;
    padding: .5rem 2rem;
    cursor: pointer;
  }
  
  /* HIDE RADIO */
  .checkedStyle [type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* IMAGE STYLES */
  [type=radio] + img {
    cursor: pointer;
  }
  
  /* CHECKED STYLES */
  .checkedStyle [type=radio]:checked + img {
    border: 4px solid #EF4871;
    border-radius: 50px;
  }
  
  .parent-signup {
      width: 70px;
      border-top: 3px solid #EF4871;
  }
  
  .center-icon {
      width: 12rem;
  }
  
  #basic-label,
  #monthly-label,
  #annual-label {
    background-color: white;
  }
  
  #monthly-label {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  
  .reset-password {
    padding-top: 2rem;
  }

  .button-div {
    margin-bottom: 100px !important;
  }
  
  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #form-input {
      width: 100% !important;
  }
    .checkedStyle {
      display: flex;
    }
    .checkedStyle [type=radio]:checked + img {
      border-radius: 20px;
    }
  }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

  #annual-label .imgstyle, 
  #monthly-label .imgstyle,
  #basic-label .imgstyle  {
    width: 8rem !important;
  }
  .checkedStyle [type=radio]:checked + img {
    border-radius: 30px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #basic-label {
    width: unset;
  }

  #annual-label .imgstyle, 
  #monthly-label .imgstyle,
  #basic-label .imgstyle  {
    width: 12rem !important;
  }
  .checkedStyle [type=radio]:checked + img {
    border-radius: 30px;
  }
}