
.childlogin-image img {
  width: 30%;
  margin-top: 1rem;
}

.forgot-password {
  padding-left: 2rem;
  margin-top: 1.5rem;
}
.notification-close:hover {
  cursor: pointer;
}

.login-container form {
  text-align: center;
}

@media (max-width: 770px) {
  .lola-img {
    width: 30%;
  }
  
  .forgot-password {
    padding-left: 1rem;
    margin-top: 1.5rem;
  }
}
  
   /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .childlogin-image img {
    width: unset;
    margin-top: 2rem;
  }
  .code-login {
    font-size: 24px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .childlogin-image img {
    width: unset;
    margin-top: 2rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .childlogin-image img {
    width: unset;
    margin-top: 2rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .childlogin-image img {
    width: 30%;
    margin-top: 2rem;
  }
}