.accordion {
  margin: 2rem auto;
   text-align: justify;
}
.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid rgb(211, 210, 210);
}
.accordion-title:hover, .accordion-title:active {
  background-color: #dbdedf;
}
.accordion-title,
.accordion-content {
  padding: 1rem;
}
.accordion-content {
   border: 1px solid rgb(211, 210, 210);
}